/**
 * SEO component that queries for data with
 *  Gatsby's useStaticQuery React hook
 *
 * See: https://www.gatsbyjs.org/docs/use-static-query/
 */

import React from 'react';
import PropTypes from 'prop-types';
import Helmet from 'react-helmet';
import { useStaticQuery, graphql } from 'gatsby';
/* eslint-disable import/no-extraneous-dependencies */
import PostmanPlan from '@postman/plan';
/* eslint-enable */
import packageData from '../../package.json';

const runtime = typeof document === 'object';

PostmanPlan().then((data) => {
  if (runtime) {
    if (typeof window.pmt === 'function') {
      window.pmt('log', [
        {
          '[@postman/plan]': data
        }
      ]);
    } else {
      // eslint-disable-next-line no-console
      console.error('window.pmt is not a function');
    }
  }
});

const { statusWidget: disallowedStatusWidget } = packageData.disallow;
const isStatusWidgetAllowed = runtime && disallowedStatusWidget.reduce((returnValue, path) => (!returnValue && document.location.pathname.indexOf(path) === -1) || returnValue, false);

function SEO({ description, lang, meta, image: seoPreviewImg, title, url, canonical, noindex }) {
  const { site } = useStaticQuery(
    graphql`
      query {
        site {
          siteMetadata {
            title
            description
            author
            default_image
            galaxy_image
            siteUrl
            assetPrefix
            tag
            beta
            log
          }
        }
      }
    `
  );

  const metaDescription = description || site.siteMetadata.description;
  const metaTitle = title || site.siteMetadata.title;
  const noIndex = noindex;
  const metaUrl = `${site.siteMetadata.siteUrl}${url}`;
  const statusWidget = (isStatusWidgetAllowed && <script src="https://ms1frkqnsp7r.statuspage.io/embed/script.js" />) || null;
  // check if there is a custom preview image for SEO
  // Switches images for Postman and Postman Galaxy (Defaults)
  const rootDomain = site.siteMetadata.siteUrl;
  const rootPrefix = site.siteMetadata.assetPrefix;
  const regex = new RegExp(`${rootDomain}/postman-galaxy/.*`);
  const defaultImage = regex.test(metaUrl) === true ? site.siteMetadata.galaxy_image : site.siteMetadata.default_image;
  const defaultImageIsRootRelative = defaultImage && !defaultImage.match(/http/) && defaultImage.match(/^\//);
  const seoPreviewImgIsRootRelative = seoPreviewImg && typeof seoPreviewImg === 'string' && !seoPreviewImg.match(/http/) && seoPreviewImg.match(/^\//);
  // Use custom preview image in json or use default set in gatsby-config.js
  let image = (seoPreviewImg && ((seoPreviewImgIsRootRelative && `${rootDomain}/${rootPrefix}/${seoPreviewImg}`) || seoPreviewImg)) || (defaultImageIsRootRelative && `${rootDomain}/${rootPrefix}/${defaultImage}`) || defaultImage;
  if (image && typeof image === 'string' && image.match(rootDomain) && !image.match(rootPrefix)) {
    image = image.replace(/\/assets\//, `/${rootPrefix}/assets/`);
  }
  if (typeof image === 'string') {
    if (!image.match('getpostman') && image.match('//assets')) {
      image = image.replace('//assets', '/assets');
    }
    if (image.match(`/${rootPrefix}//${rootPrefix}`)) {
      image = image.replace(`/${rootPrefix}//${rootPrefix}`, `/${rootPrefix}`);
    }
  }

  const imgIsAbsolutePostman = !defaultImageIsRootRelative && !seoPreviewImgIsRootRelative && typeof image === 'string' && image.match('postman');

  if (site.siteMetadata.beta && typeof image === 'string' && image.match('postman.com') && !image.match('getpostman.com') && !image.match('voyager.postman')) {
    image = image.replace('postman.com', 'postman-beta.com');
  }

  if (typeof image === 'string' && (site.siteMetadata.log === '1' || site.siteMetadata.log === 'seo')) {
    const icon = (imgIsAbsolutePostman && '✅') || '✓';

    /* eslint-disable no-console */
    console.log(`${icon} ${image}`);
    /* eslint-enable */
  }
  const htmlTitle = `${site.siteMetadata.tag}${title}`;

  if (runtime && window.pmt) {
    window.pmt('scalp', ['pm-analytics', 'load', document.location.pathname]);
  }

  return (
    <Helmet
      htmlAttributes={{
        lang
      }}
      title={htmlTitle}
      titleTemplate="%s"
      link={canonical ? [{ rel: 'canonical', key: canonical, href: canonical }] : []}
      meta={[
        {
          name: 'description',
          content: metaDescription
        },
        {
          name: noIndex && 'robots',
          content: noIndex && 'noindex'
        },
        {
          property: 'og:url',
          content: metaUrl
        },
        {
          property: 'og:title',
          content: `${metaTitle}`
        },
        {
          property: 'og:site_name',
          content: site.siteMetadata.title
        },
        {
          property: 'og:description',
          content: metaDescription
        },
        {
          property: 'og:type',
          content: 'website'
        },
        {
          name: 'twitter:creator',
          content: site.siteMetadata.author
        },
        {
          name: 'twitter:title',
          content: metaTitle
        },
        {
          name: 'twitter:description',
          content: metaDescription
        },
        {
          name: 'twitter:site',
          content: site.siteMetadata.author
        }
      ]
        .concat(
          image
            ? [
                {
                  property: 'og:image',
                  content: image
                },
                {
                  property: 'twitter:image',
                  content: image
                },
                {
                  name: 'twitter:card',
                  content: 'summary_large_image'
                }
              ]
            : [
                {
                  name: 'twitter:card',
                  content: 'summary'
                }
              ]
        )
        .concat(meta)}
    >
      <link rel="preconnect" href="https://voyager.postman.com" crossOrigin="true" />
      <link href="https://voyager.postman.com/font/fonts.css" rel="stylesheet" />
      <noscript>{`<link href="https://voyager.postman.com/font/fonts.css" rel="stylesheet" type="text/css" />`}</noscript>
      {statusWidget}
    </Helmet>
  );
}

SEO.defaultProps = {
  lang: 'en',
  meta: [],
  description: '',
  url: '',
  image: {},
  noindex: false
};

SEO.propTypes = {
  description: PropTypes.string,
  lang: PropTypes.string,
  meta: PropTypes.arrayOf(PropTypes.shape({})),
  title: PropTypes.string.isRequired,
  url: PropTypes.string,
  noindex: PropTypes.bool,
  canonical: PropTypes.string.isRequired,
  image: PropTypes.string
};

export default SEO;
